import { ApolloError, ApolloQueryResult, TypedDocumentNode } from '@apollo/client'
import { QueryOptions } from '@apollo/client/core/watchQueryOptions'
import { SUSPENSE } from '@react-rxjs/core'
import { switchMapSuspended } from '@react-rxjs/utils'
import { combineLatest, Observable } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { coerceCaughtToLeft } from '../../utils/rx/errors'
import { latestAccessToken$ } from '../auth/state'
import { latestApolloClient$ } from '../graphql/apollo'

export interface SearchQueryLeft {
  _tag: 'Left'
  error: ApolloError | Error
}

export interface SearchQueryRight<TData = unknown> {
  _tag: 'Right'
  data: TData
}

export type SearchQueryResult<TData = unknown> = SearchQueryLeft | SearchQueryRight<TData>

const toSearchQueryResult = <TData = unknown>({
  error,
  data,
}: ApolloQueryResult<TData>): SearchQueryResult<TData> =>
  // assumes errorPolicy === 'none' (error should have been thrown, but we'll check just in case)
  error
    ? {
        _tag: 'Left' as const,
        error: error,
      }
    : { _tag: 'Right' as const, data: data }

/**
 * Execute query using `ApolloClient::query` (Promise API). Returns one result and completes
 * (does not observe to cache updates). Emits `SUSPENSE` initially regardless of whether query
 * is cached.
 */
export const searchQuery =
  <TData = unknown, TVariables = unknown>(query: TypedDocumentNode<TData, TVariables>) =>
  (
    options: Omit<QueryOptions<TVariables, TData>, 'query'>,
  ): Observable<typeof SUSPENSE | SearchQueryResult<TData>> =>
    combineLatest([latestAccessToken$, latestApolloClient$]).pipe(
      // start with `SUSPENSE` since Promise API cannot provide network status updates
      switchMapSuspended(([token, client]) =>
        client.query<TData, TVariables>({
          ...options,
          query,
          context: {
            ...options.context,
            uri: process.env.GATSBY_SEARCH_URL,
            ...(token && { token }),
          },
        }),
      ),
      map((value) => (value === SUSPENSE ? value : toSearchQueryResult(value))),
      catchError(coerceCaughtToLeft),
    )
